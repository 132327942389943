import React from 'react';

function ProficeincyText() {
    return (
        <p className='proficiency-text m-auto pt-4 pb-4 mb-4'>
            I am currently persuing my Masters degree in Information Systems at the Free University Berlin. 
            Here I am specializing in data driven systems, businessmodels and entrepeneurship. 
            In addition I work part time at [j]karef GmbH where I do Fullstack Web Development of data analysis tools.
            Through my studies and my past employments I have been able to build a solid foundation in a wide range and an expert skillevel in a select group of skills.
        </p>
    );
  }

  export default ProficeincyText;